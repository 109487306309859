<template>

  <Generic
    title="Consulta de protocolos"
    :protocolos="this.protocol.rows"
    :filters="filters"
    @changeFilters="changeFilters"
    name="Protocolo"
  >
    <template #filters>
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group>
            <label>Número do protocolo</label>
              <b-form-input
                v-model="filters.protocolo"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </Generic>
</template>
<script>
import Generic from './Generic';

export default {
  components: {
    Generic,
  },

  data() {
    return {
      protocol: [],
      filters: {
        protocolo: null,
        unidade: null,
        subSetor: null,
        processo: null,
        setor: null,
        initDate: null,
        endDate: null,
        statusProtocolo: null,
        check_material_substitution: true,
        used_on_key_process_type: 'saidaArsenal',
      },
    };
  },
  methods: {
    changeFilters(prop, val) {
      this.filters[prop] = val;
    },
    validateState(ref) {
      if (!this.$refs[ref] || this.$refs[ref][0] || this.$refs[ref][0].value === null) return null;
      if (this.$refs[ref] && this.$refs[ref][0].value) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>

.invision-select-typing .vs__dropdown-toggle {
  height: 100%;
  padding: 0;
  border-radius: 7px;
  border: 1px solid #ced4da;
}
.invision-select-typing .vs__selected {
  padding: 4.5px 16px;
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.invision-select-typing .vs__search {
  padding: 0.4rem;
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.invision-select-typing .vs__dropdown-option {
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.invision-select-typing {
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
</style>
