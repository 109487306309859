<template>
  <div>
    <div :class="listClass" class="transition">
      <cardless-base>
        <slot name="header">
          <PageTitle :title="title" />
        </slot>

        <b-row class="p-0 justify-content-center">
          <b-col sm="11">
            <b-row>
              <b-col sm="3" style="margin-top:10px">
                <RangeDatePicker
                  :initDate="initDate"
                  :endDate="endDate"
                  :isBusy="loading"
                  @selected="rangeDateSelected"
                />
              </b-col>

              <b-col v-if="multiUnidades" sm="3" style="margin-top:10px">
                <GenericSelect
                  name="unidade"
                  ref="select-id_unidade"
                  labelKey="nome"
                  route="unidade"
                  v-model="unidade"
                  @input="(v) => selectedInSelect('unidade', v)"
                  :firstOption="{ value: null, label: 'Unidade' }"
                ></GenericSelect>
              </b-col>
              <b-col sm="3" style="margin-top:10px">
                <GenericSelect
                  name="setor"
                  ref="select-id_setor"
                  labelKey="nome"
                  route="setor"
                  v-model="setor"
                  @input="(v) => applyFilters('setor', v)"
                  :firstOption="{ value: null, label: 'Setor' }"
                  :customFilters="{ id_setor: [...setorUser] }"
                  :disabledFirstOption="Boolean(setorUser.length)"
                />
              </b-col>
              <b-col sm="3" style="margin-top:10px">
                <b-form-select
                  id="select"
                  :value="filters.statusProtocolo"
                  ref="select-status-protocolo"
                  @input="selectedStatusProtocolo"
                  :options="filterStatus"
                  class="invision-input"
                  v-model="selectedStatus"
                />
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="1" style="margin-top:10px">
            <b-button
              v-if="!nofilters"
              @click="toggleFilters()"
              variant="none"
              class="btn toggle-button"
              :data-cy="`Adicionar ${name}`"
            >
              <SlidersIcon v-b-tooltip.hover.top="'Filtrar'" />
            </b-button>
          </b-col>
        </b-row>

        <TabelaProtocolo
          title="Lista de protocolos"
          :protocolos="protocol"
          :isBusy="loading"
          :tableFields="tableFields"
        />

        <nav
          aria-label="Navegar entre as páginas"
          class="paginador"
          v-if="page"
        >
          <div>
            Mostrando resultados {{ first }} a {{ last }}.
          </div>
          <ul class="pagination justify-content-center mb-0">
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              :per-page="rowsPerPage"
              hide-goto-end-buttons
            ></b-pagination>
          </ul>
        </nav>
      </cardless-base>
      <!-- template-base -->

      <div class="transition filters-aside p-4" :class="filterAsideClasses">
        <button
          variant="none"
          class="btn toggle-button p-0"
          @click="toggleFilters"
        >
          <x-icon />
        </button>
        <b-row align-h="between" class="pt-4 pb-4">
          <b-col> <h2 class="m-0">Filtro</h2> </b-col>
          <b-col class="text-right">
            <b-button
              type="button"
              variant="none"
              class="sm primary-light-contained-button"
              :disabled="loading"
              @click="update(1, 20)"
              >Buscar</b-button
            >
          </b-col>
        </b-row>
        <slot name="filters" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import moment from 'moment';
import loginService from '@/services/login';

import GenericApi from '@/services/genericRequest';
import GenericSelect from '@/components/Form/GenericSelect';
import RangeDatePicker from '@/components/Form/RangeDatePicker';

import CardlessBase from '@/templates/CardlessBase';
import PageTitle from '@/templates/PageTitle';

import TabelaProtocolo from './TabelaProtocolo';

export default {
  name: 'Generic',
  components: {
    CardlessBase,
    PageTitle,
    TabelaProtocolo,
    GenericSelect,
    RangeDatePicker,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    editRoute: {
      type: String,
      required: false,
    },
    apiFunction: {
      type: String,
      required: false,
      default() {
        return 'get';
      },
    },
    api: {
      type: Object,
      required: false,
      default() {
        return GenericApi;
      },
    },
    canShowAllResults: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
    permissionsToWrite: {
      type: Array,
      default() {
        return [];
      },
    },
    idKey: {
      type: String,
      default: '',
    },
    nofilters: {
      type: Boolean,
      default: false,
    },
    noadd: {
      type: Boolean,
      default: false,
    },
    editRouteIndex: {
      type: Function,
      default: () => 0,
    },
    hasPagination: {
      type: Boolean,
      default: false,
    },
    protocolos: {
      type: Array,
    },
  },
  data() {
    return {
      state: 0,
      lista: [],
      loading: false,
      errMsg: '',
      pagination: false,
      page: 0,
      pages: 1,
      rowsPerPage: 20,
      first: 0,
      last: 0,
      count: 0,
      currentPage: 1,
      protocol: [],
      tableFields: [],
      initDate: null,
      endDate: null,
      usuarioLogado: null,
      CAN_WRITE: loginService.verifyPermissions(this.permissionsToWrite),
      setorUser: [],

      showingFilters: false,
      unidade: null,
      subSetor: null,
      tipoProcesso: null,
      setor: null,
      selectedStatus: null,
      filterStatus: [
        {
          value: null,
          text: 'Status',
        },
        {
          value: 'pendente',
          text: 'Pendente',
        },
        {
          value: 'concluido',
          text: 'Concluido',
        },
      ],
      multiUnidades: false,
    };
  },
  watch: {
    currentPage() {
      this.paginate(this.currentPage);
    },
    filters: {
      deep: true,
      handler(filters) {
        if (filters.protocolo && !(filters.endDate)) {
          this.$emit('changeFilters', 'initDate', null);
          this.$emit('changeFilters', 'endDate', null);
          this.initDate = null;
          this.endDate = null;
        }
      },
    },
  },
  computed: {
    linhas() {
      return this.lista.map((row) => {
        const idKey = this.idKey
          || Object.keys(row).find((key) => key.slice(0, 2) === 'id');

        const indexRoute = this.editRouteIndex(row);

        return {
          href: {
            name: this.editRoutes[indexRoute],
            params: { id: row[idKey] },
          },
          cols: this.colsMap(row),
          id: row[idKey],
        };
      });
    },
    parsedCols() {
      return this.cols.map((c, idx) => {
        if (this.colsName[idx]) return c;
        return { value: c, sortable: false };
      });
    },
    editRoutes() {
      return this.editRoute.split(',');
    },
    listClass() {
      return this.showingFilters ? 'w-75' : 'w-100';
    },
    filterAsideClasses() {
      return this.showingFilters ? 'show' : 'hidden';
    },
    TiposFilter() {
      return { withoutTipos: [] };
    },
    ...mapState({
      genericData: (state) => state.genericData,
    }),
  },
  mounted() {
    this.getFirstOptionSetor();
    this.update();
    if (Array.isArray(this.genericData.unidade) && this.genericData.unidade.length > 1) this.multiUnidades = true;
  },

  methods: {
    getFirstOptionSetor() {
      this.usuarioLogado = loginService.getUser();
      const viewAllSetores = loginService.verifyPermissions(['rw_material', 'r_visualizar']);

      if(!viewAllSetores) {
        const { areas } = this.usuarioLogado;

        const setores = this.genericData.unidade.reduce((acc, u) => {
          const setor = u.setor.reduce((accArea, s) => {
            const filterAreas = [];
            areas.forEach((a) => {
              if (s.nome === a.toUpperCase()) {
                filterAreas.push(s);
                this.setorUser.push(s.id_setor)
              }
            });
            if (filterAreas.length) return [...filterAreas, ...accArea]
            return accArea;
          }, []);
          if (setor) return [...setor, ...acc];
          return acc;
        }, []);
      }
      
      return;
    },

    selectedStatusProtocolo(v) {
      this.filters.statusProtocolo = v;
      this.update();
    },
    rangeDateSelected(initDate, endDate) {
      this.initDate = initDate;
      this.endDate = endDate;
      this.$emit('changeFilters', 'initDate', initDate.format());
      this.$emit('changeFilters', 'endDate', endDate.format());
      this.update();
    },
    selectedInSelect(prop, value) {
      this.applyFilters(prop, value);
      if ((prop === 'subSetor' || prop === 'unidade') && this.$refs['select-id_tipo_processo']) {
        if (value) {
          this.state += 1;
          this.tipoProcesso = null;
          this.applyFilters('processo', null);
        } else {
          this.state += 1;
          this.tipoProcesso = null;
          this.applyFilters('processo', null);
        }
      }
    },
    fillSelectOptions(key, parentValue = null) {
      if (key === 'processo' && this.$refs['select-id_sub_setor']) {
        const selectedIdSubSetor = parentValue || this.$refs['select-id_sub_setor'].value;
        if (!selectedIdSubSetor) return null;
        const subSetor = this.$refs['select-id_sub_setor'].apiResponse.find(
          (item) => item.id_sub_setor === selectedIdSubSetor,
        );
        if (!subSetor) return null;

        const unidadeInfo = this.genericData.unidade;
        let selectedIdUnidade = '';
        unidadeInfo.forEach((findUnidade, index) => {
          if (findUnidade.id_unidade === this.unidade) {
            selectedIdUnidade = index + 1;
          }
        });

        if (!selectedIdUnidade) return null;

        const processos = [];
        unidadeInfo[selectedIdUnidade - 1].tipoProcesso.forEach((processo) => {
          if (processo.processo.length) {
            processo.processo.forEach((fss) => {
              const processoExiste = processos.some(
                (processoEval) => processoEval.id === processo.id_tipo_processo
                  && processoEval.nome === processo.nome
                  && processoEval.subSetor === fss.fluxoSubSetor.id_sub_setor,
              );
              if (!processoExiste) {
                processos.push({
                  id: processo.id_tipo_processo,
                  nome: processo.nome,
                  subSetor: fss.fluxoSubSetor.id_sub_setor,
                });
              }
            });
          }
        });

        processos.sort((a, b) => (a.subSetor > b.subSetor || b.id > a.id ? 1 : -1));
        const result = processos.map((processo) => {
          if (processo.subSetor === selectedIdSubSetor) {
            return { value: processo.id, text: processo.nome };
          }
          return null;
        });

        return result.filter((resultado) => resultado !== null);
      }
      return null;
    },
    applyFilters(prop, val) {
      this.$emit('changeFilters', prop, val);
      this.update(1, 20);
    },
    moment(date) {
      return moment(date);
    },
    setStateDataTable() {
      this.page = 0;
      this.update(1, this.rowsPerPage);
    },
    changeResults(event) {
      this.page = 0;
      this.update(1, event.target.value);
    },
    paginate(p) {
      if (p === this.page) return;
      this.page = p;
      this.update(this.page, this.rowsPerPage);
    },
    async update(page = 1, rowsPerPage = 20) {
      try {
        this.loading = true;
        this.pagination = true;

        const { filters } = this;
        if(this.setorUser.length && !filters.setor) filters.setor = this.setorUser[0];      
        Object.keys(filters).forEach((key) => {
          if (!filters[key]) delete filters[key];
        });

        if (filters.statusProtocolo) {
          if (filters.statusProtocolo === 'concluido') filters.finalizado = true;
          if (filters.statusProtocolo === 'pendente') filters.finalizado = false;
        }

        if (filters.loteRecebimento) this.resetFiltersValue();    

        const protocol = await GenericApi.get(
          { page, rowsPerPage, filters },
          'protocolo/listProtocolo',
        );
        this.page = page;
        this.pages = protocol.pages;
        this.first = protocol.count === 0 ? 0 : (page - 1) * rowsPerPage + 1;
        this.last = protocol.rows.length + rowsPerPage * (page - 1);
        this.count = protocol.count;
        this.rowsPerPage = rowsPerPage;
        this.protocol = protocol.rows;
        this.tableFields = protocol.tableFields;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }
        swal({
          title: 'Oops!',
          text:
            errorMessage || 'Ocorreu um problema ao recuperar os protocolos',
          icon: 'warning',
          button: 'Continuar',
        });
      }
    },
    toggleFilters() {
      this.showingFilters = !this.showingFilters;
    },
    resetFiltersValue() {
      this.unidade = null;
      this.subSetor = null;
      this.tipoProcesso = null;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;
$primary-color-40: #21cca9;
$neutral-color-80: #35384d;
$neutral-color-70: #5e627a;
$neutral-color-60: #7e829f;

.invision-select {
  width: auto;

  color: #5e627a;
  background-color: #f0f1fc;

  border: none;
  margin-left: 12px;
}

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.feather {
  margin-bottom: 4px;
}
.spacer {
  margin-left: auto;
}

.transition {
  transition: 0.3s ease-in-out;
}

.filters-aside {
  z-index: 100;
  position: fixed;
  min-height: 100%;
  right: 0;
  top: 64px;
  background-color: white;
}
.filters-aside.show {
  width: 22%;
}
.filters-aside.hidden {
  width: 0 !important;
  padding: 0 !important;
}

.toggle {
  margin-left: 14rem;
}

.toggle-button {
  color: $neutral-color-70;
}

label {
  color: $neutral-color-70;
}

.select-box {
  display: flex;
  width: 600px;
}

.row {
  justify-content: space-between;
  padding: 0 1rem;
}

.invision-select,
.invision-input,
.invision-select.invision-input,
.form-control.invision-input,
.invision-file.invision-input .b-form-file.invision-input {
  margin-right: 1rem;
}

select:hover {
  border-color: #21cca9;
}

select:active,
select:focus,
select:hover {
  color: #21cca9;
}
</style>
