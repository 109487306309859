<template>
  <div class="white-box">
    <main class="card invision-card elevate-2 borderless container padding">
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <span class="title">{{ title }}</span>
          <div v-if="isBusy">
            <br/>
            <b-spinner class="spinner" />
          </div>
          <b-table
            v-else
            :fields="tableFields"
            :items="protocolos"
            striped
            class="mt-3"
            responsive
            show-empty
            empty-text="Não existem materiais selecionados"
          >
            <template #cell(state)="{ item }">
              <b-button
                class="btn btn-sm text--black"
                variant="outline-dark"
                @click="goToEditPage(item)"
              >
                <eye-icon class="w20px"/>
              </b-button>
            </template>
            <template #cell(status)="form">
              <div v-if="typeof form.item.status === 'string'"
              :class="'change-color-yellow'">
                {{ form.item.status ? form.item.status : '-' }}
              </div>
              <div v-else-if="form.item.status === null">
              </div>
              <div v-else
              :class="form.item.status ? 'change-color-green' : 'change-color-grey'">
                {{ form.item.status ? 'Concluído' : 'Pendente' }}
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </main>
  </div>
</template>

<script>

import GenericApi from '@/services/genericRequest';

export default {
  data() {
    return {
      title: '',
      tableFields: [],
    };
  },

  props: {
    protocolos: {
      type: Array,
      required: true,
    },
    isBusy: {
      type: Boolean,
    },
  },

  mounted() {
    this.getConfiguracoesRelatorio();
  },

  methods: {
    goToEditPage(item) {
      const routeData = this.$router
        .resolve(`/consultaProtocolo/visualizar/${item.protocolo}/${item.id_tipo_protocolo}`);
      window.open(routeData.href, '_blank');
    },

    async getConfiguracoesRelatorio() {
      const configuracoesRelatorio = await GenericApi.get({}, 'configuracaoRelatorio/CONSULTA_PROTOCOLO');
      this.tableFields = configuracoesRelatorio.fields;
      this.title = configuracoesRelatorio.descricao;
    },
  },
};
</script>

<style scoped>
.white-box {
  margin-top: 2rem;
}
.card.invision-card.borderless {
  border: 0;
  padding: 2rem;
}
.title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #35384d;
}
.change-color-grey {
  background-color: #F0F1FC;
  text-align: center;
  border-radius: 5px;
  padding: 0.4rem;
  color: #7E829F;
  font-weight: 600;
}
.change-color-green {
  background: rgba(115, 230, 207, 0.2);
  text-align: center;
  border-radius: 5px;
  padding: 0.4rem;
  color: #21CCA9;
  font-weight: 600;
}
.change-color-yellow {
  text-align: center;
  border-radius: 5px;
  padding: 0.4rem;
  background-color: #ffac686e;
  color: #e67a22;
  font-weight: 600;
}
.spinner {
  display: table;
  margin: 0 auto;
}
</style>
